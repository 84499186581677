<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Details"
      :actions="actions"
      isPreview
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-btn depressed color="success">Akzeptieren</v-btn>
              <v-btn depressed color="error">Ablehnen</v-btn>
            </v-col>
          </v-row>
          <v-row class="match-height">
            <v-col cols="12" sm="12" md="7">
              <v-card height="600px"> Belege </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="5">
              <v-card>
                <v-card-title class="subheading mb-n14">
                  <v-spacer></v-spacer>
                  <v-chip
                    color="green lighten-4"
                    class="green--text text--darken-4"
                    >Bezeichnung</v-chip
                  ></v-card-title
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account-question</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle>Begründung</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-clock-start</v-icon>
                  </v-list-item-icon>
                  <!-- <v-list-item-subtitle
                          >{{
                            timestampConverter(expense.period.start.timestamp)
                          }}
                          Uhr
                        </v-list-item-subtitle> -->
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-history" title="Logs"> </Subheader>
              <div class="">
                <v-timeline dense align-top>
                  <v-timeline-item
                    fill-dot
                    class="white--text mb-8 mr-8"
                    color="white"
                    icon-color="grey"
                    icon="mdi-pencil"
                    small
                  >
                    <v-card disabled>
                      <v-card-text>
                        <v-textarea
                          v-model="input"
                          dense
                          label="Kommentar hinzufügen"
                          hide-details
                          disabled
                          outlined
                          no-resize
                          auto-grow
                          counter
                        >
                        </v-textarea>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-checkbox
                          dense
                          disabled
                          label="Benachrichtigung per E-Mail senden"
                          persistent-hint
                          hint="Eine Benachrichtigung über diesen Eintrag wird per E-Mail an ausgewählte Personen gesendet."
                        ></v-checkbox>
                        <v-spacer></v-spacer
                        ><v-btn
                          class="mx-0"
                          color="success"
                          depressed
                          disabled
                          @click="comment"
                        >
                          Hinzufügen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-timeline-item>

                  <v-slide-x-transition group>
                    <div
                      v-for="event in timeline"
                      :key="event.id"
                      class="mb-8 mr-8"
                    >
                      <v-timeline-item color="primary" fill-dot small>
                        <v-card flat class="elevation-3">
                          <v-card-text>
                            <v-row justify="space-between">
                              <v-col cols="7" v-html="event.text"></v-col>
                              <v-col
                                class="text-right"
                                cols="5"
                                v-text="event.time"
                              ></v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>
                  </v-slide-x-transition>
                  <v-divider></v-divider>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
// import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import {
  FINANCE_EXPENSES_update,
  FINANCE_EXPENSES_delete,
} from "@/data/permission-types.js";

export default {
  name: "finance-expenses-details",
  components: {
    NavigationBar,
    Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${FINANCE_EXPENSES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${FINANCE_EXPENSES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      events: [],
      input: null,
      nonce: 0,

      // expense: {
      //   period: {
      //     start: {
      //       date: "11.07.2022",
      //       time: "00:00",
      //     },
      //     end: {
      //       date: "12.07.2022",
      //       time: "00:00",
      //       isOpen: false,
      //     },
      //   },
      //   reason: {
      //     name: "",
      //     details: "",
      //   },
      //   automation: {
      //     automateEventResponses: true,
      //     eventResponses: {
      //       status: "2",
      //       comment: "",
      //     },
      //   },
      // },
      expense: null,
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      // db.collection("organizations")
      //   .doc(this.$route.params.organizationId)
      //   .collection("expenses")
      //   .doc(this.$route.params.itemId)
      //   .get()
      //   .then((doc) => {
      //     if (doc.exists) {
      //       this.expense = doc.data();
      //     } else {
      //       // doc.data() will be undefined in this case
      //       console.log("No such document!");
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log("Error getting document:", error);
      //     return false;
      //   });
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
            .split(" ")
            .map((v) => v.charAt(0))
            .join("")}`;
        }),
      });

      this.input = null;
    },
    editItem() {
      this.$router.push({
        name: "finance-expenses-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {},
  },
};
</script>

<style>
.v-timeline-item__dot--small {
  height: 30px;
  width: 30px;
}
</style>
