<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Auslagenersatz beantragen"
      :actions="actions"
      isPreview
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        @submit.prevent="createItem"
        ref="form"
        class="col s8"
      >
        <expense-editor v-model="expense" mode="new"></expense-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { Timestamp, auth } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ExpenseEditor from "@/components/finance/expenses/ExpenseEditor.vue";
import { FINANCE_EXPENSES_create } from "@/data/permission-types.js";
import { ADMIN, FINANCE } from "@/store/modules.js";
import { GET_USER_LIST, CREATE_LEAVE } from "@/store/action-types.js";

export default {
  name: "finance-expenses-new",
  components: {
    NavigationBar,
    ExpenseEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          permission: `${FINANCE_EXPENSES_create}`,
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },

      expense: {
        expenseDate: "",
        category: {
          id: "",
          title: "",
        },
        description: "",
        notes: "",
        user: {
          displayName: auth.currentUser.displayName,
          uid: auth.currentUser.uid,
        },
        paidBy: "", // "member (to reimburse)" or "organization"
        budget: {
          id: "",
          title: "",
        },
        journal: {
          id: "",
          title: "",
        },
        status: "",
        type: {
          id: "expense",
          title: "Auslagenersatz",
        },
        total: 0.0,
        currency: "EUR",
        reason: {
          id: "",
          details: "",
        },
        activity: {
          id: "",
        },
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      //   if (this.permissionToManageExpenses) {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      //   }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        if (
          this.expense.user.uid !== auth.currentUser.uid &&
          !this.permissionToManageExpenses
        ) {
          alert(
            "Sie dürfen keine Abmeldungen für andere Mitglieder erstellen."
          );
        } else if (
          this.convertToTimestamp(
            this.expense.period.start.date,
            this.expense.period.start.time
          ).valueOf() >
          this.convertToTimestamp(
            this.expense.period.end.date,
            this.expense.period.end.time
          ).valueOf()
        ) {
          alert("Fehler: Das Startdatum muss vor dem Enddatum liegen.");
        } else {
          this.$store
            .dispatch(`${FINANCE}${CREATE_LEAVE}`, {
              organizationId: this.$route.params.organizationId,

              period: {
                start: {
                  timestamp: this.convertToTimestamp(
                    this.expense.period.start.date,
                    this.expense.period.start.time
                  ),
                },
                end: {
                  timestamp: this.convertToTimestamp(
                    this.expense.period.end.date,
                    this.expense.period.end.time
                  ),
                  isOpen: this.expense.period.end.isOpen,
                },
              },
              reason: {
                id: this.expense.reason.id,
                details: this.expense.reason.details,
              },
              automation: {
                automateEventResponses:
                  this.expense.automation.automateEventResponses,
                eventResponses: {
                  status: this.expense.automation.eventResponses.status,
                  comment: this.expense.automation.eventResponses.comment,
                },
              },

              user: {
                displayName: auth.currentUser.displayName,
                uid: auth.currentUser.uid,
              },
            })
            .then(function () {
              this.$router.push({
                name: "finance-expenses",
                params: {
                  organizationId: this.$route.params.organizationId,
                },
              });
            })
            .catch(function (error) {
              alert(
                "Es ist ein Fehler aufgetreten. \n\nKontaktiere den Support mit folgender Meldung\n" +
                  error
              );
              console.error(error);
            });
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
