<template>
  <div>
    <NavigationBar
      titel="Auslagenantrag bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              v-model="helpers.formIsValid"
              ref="form"
              @submit.prevent="updateItem"
            >
              <v-col cols="12">
                <expense-editor v-model="expense"></expense-editor>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
// import { db } from "@/firebase";
// import { SCHEDULING } from "@/store/modules.js";
// import { UPDATE_EVENT_TEMPLATE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "finance-expense-edit",
  components: {
    NavigationBar,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          //   function: this.updateItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
  },
};
</script>

<style></style>
