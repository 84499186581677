<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        Vorschläge:
                        <v-chip-group
                          v-model="expenseLocal.title"
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="Verpflegung  (Frühstück) ED"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            >Verpflegung (Frühstück) ED
                          </v-chip>
                          <v-chip
                            label
                            value="Verpflegung  (Mittagessen) ED"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            >Verpflegung (Mittagessen) ED
                          </v-chip>
                          <v-chip
                            label
                            value="Verpflegung  (Abendessen) ED"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            >Verpflegung (Abendessen) ED
                          </v-chip>
                          <v-chip
                            label
                            value="Getränkeeinkauf"
                            class="transparent-background font-weight-medium mb-3"
                            >Getränkeeinkauf</v-chip
                          >
                        </v-chip-group>
                        <v-text-field
                          v-model.trim="expenseLocal.title"
                          label="Ausgabegrund"
                          outlined
                          hide-details="auto"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6" sm="12">
                        <v-radio-group label="Bezahlt von:" mandatory row>
                          <v-radio
                            label="Person (zu erstatten)"
                            value="expense"
                          ></v-radio>
                          <v-radio
                            label="Organisation"
                            value="advance"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <v-autocomplete
                          :items="memberships"
                          outlined
                          persistent-hint
                          return-object
                          label="Auslegende Person"
                          item-text="displayName"
                          hide-details="auto"
                          :disabled="permissionToManageexpenses"
                          :readonly="permissionToManageexpenses"
                          item-value="uid"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-file-multiple" title="Belege"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model.trim="expenseLocal.receiptIssuer"
                          label="Belegaussteller"
                          outlined
                          prepend-inner-icon="mdi-store"
                          hide-details="auto"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.number="expenseLocal.total"
                          type="number"
                          label="Summe"
                          outlined
                          prepend-inner-icon="mdi-sigma"
                          hide-details="auto"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <file-upload-dropzone
                          v-model="files"
                          :multiple="true"
                          :acceptedFileTypes="['image/*', 'application/pdf']"
                        ></file-upload-dropzone>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-account-credit-card"
              title="Anteilige Umlage auf Personen"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-col cols="12" md="6" sm="6" xs="12">
                    <v-select
                      v-model="expenseLocal.event"
                      item-value="id"
                      item-text="title"
                      outlined
                      label="Verknüpfte Veranstaltung"
                      hide-details="auto"
                      :rules="[rules.required]"
                    >
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.type.title
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" xs="12">
                    <v-btn depressed
                      ><v-icon left>mdi-account-arrow-right</v-icon>Personen aus
                      Veranstaltung/Dienst übernehmen</v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      :items="memberships"
                      outlined
                      persistent-hint
                      return-object
                      label="Person für anteilige Umlage"
                      item-text="displayName"
                      hide-details="auto"
                      item-value="uid"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-radio-group label="Umlageart:" mandatory row>
                      <v-radio label="Gleiche Umlage" value="expense"></v-radio>
                      <v-radio
                        label="Benutzerdefiniert"
                        value="advance"
                      ></v-radio>
                    </v-radio-group> </v-col
                ></v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-dots-horizontal-circle"
              title="Sonstiges"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" sm="6" xs="12">
                        <v-select
                          v-model="expenseLocal.category"
                          item-value="id"
                          item-text="title"
                          outlined
                          label="Kategorie"
                          hide-details="auto"
                          :rules="[rules.required]"
                        >
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{
                                  item.description
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" sm="6" xs="12">
                        <v-select
                          v-model="expenseLocal.budget"
                          item-value="id"
                          item-text="title"
                          outlined
                          disabled
                          label="Kostenstelle"
                          hide-details="auto"
                          :rules="[rules.required]"
                        >
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{
                                  item.description
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          counter
                          clearable
                          no-resize
                          rows="3"
                          label="Ergänzende Beschreibung"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import FileUploadDropzone from "@/components/_systemwide/FileUploadDropzone.vue";
// import { AVAILABILITY_expenseS_manage_all } from "@/data/permission-types.js";
export default {
  name: "expense-editor",
  props: {
    expense: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    FileUploadDropzone,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      files: [],
    };
  },
  model: {
    prop: "expense",
    event: "expenseChange",
  },
  computed: {
    expenseLocal: {
      get: function () {
        return this.expense;
      },
      set: function (value) {
        this.$emit("expenseChange", value);
      },
    },
    permissionToManageexpenses() {
      return true;
    },
    expenseSuggestions() {
      return this.$store.state.finance.expenseSuggestions;
    },
    memberships() {
      var result = this.$store.state.admin.membershipsPrivate.map((item) => {
        const container = {
          displayName: item.user.displayName,
          uid: item.user.uid,
        };
        return container;
      });
      return result;
    },
    expenseCategories() {
      return this.$store.state.finance.expenseCategories;
    },
    expenseBudgets() {
      return this.$store.state.finance.expenseBudgets;
    },
  },
  methods: {},
};
</script>

<style>
.transparent-background {
  background-color: transparent !important;
}
</style>
